import axios from 'axios';

// Base API URL
const API_URL = 'https://nlp.henzi.org/api'; // replace with your backend URL

// Get story prompt
export const getPrompt = async () => {
    return axios.get(`${API_URL}/prompt`);
};

// Generate story options by storyId
export const generateOptions = async (storyId) => {
    return axios.get(`${API_URL}/options`, { params: { storyId } });
};

// Save option by optionId and storyId
export const saveOption = async (optionId, storyId) => {
    return axios.get(`${API_URL}/save_option`, { params: { option_id: optionId, storyId } });
};

// Get story by storyId (conditionally add storyId if present)
export const getStory = async (storyId = null) => {
    const config = {};

    // Only add the storyId param if it's not null or undefined
    if (storyId) {
        config.params = { storyId };
    }

    return axios.get(`${API_URL}/story`, config);
};