import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';

// PrimeReact CSS imports
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.min.css';                   // core css
import 'primeicons/primeicons.css';                                 // icons
import '/node_modules/primeflex/primeflex.css';                     // PrimeFlex for utility classes

const Stories = () => {
    const [stories, setStories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [first, setFirst] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchStories = async () => {
            try {
                const response = await fetch('https://nlp.henzi.org/api/stories');
                const data = await response.json();
                setStories(data.stories);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching stories:', error);
                setLoading(false);
            }
        };

        fetchStories();
    }, []);

    const storyIdTemplate = (rowData) => {
        return (
            <Link to={`/app/?storyId=${rowData.storyId}`}>
                {rowData.storyId}
            </Link>
        );
    };

    const timestampTemplate = (rowData) => {
        return new Date(rowData.timestamp * 1000).toLocaleString();
    };

    const handleNavigateToApp = () => {
        navigate('/app');
    };

    return (
        <div className="card" style={{ width: '80%', margin: '0 auto' }}>
            <div className="flex justify-content-between align-items-center mb-3">
                <h1>Stories</h1>
                <Button
                    label="Go to App"
                    icon="pi pi-arrow-right"
                    onClick={handleNavigateToApp}
                    className="p-button-primary"
                />
            </div>
            <div className="app-description mb-4">
                <p>
                    This application leverages React, FastAPI, and OpenAI's ChatGPT to generate engaging story content.
                    As a user, you'll be presented with various story options generated by ChatGPT. Your task is to
                    select the content that resonates the most or best tells the story you envision.
                    As you build your story, you’ll curate and refine the content to craft a compelling narrative.
                </p>
                <p>
                    This project is made possible with support from The Henzi Foundation. The foundation raises funds to assist with child funerals, providing support regardless of the child's race, sex, creed, or identity. Their dedication to helping families in need has been instrumental in bringing this application to life.
                </p>
            </div>
            <DataTable
                value={stories}
                loading={loading}
                responsiveLayout="scroll"
                paginator
                rows={5}
                first={first}
                onPage={(e) => setFirst(e.first)}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                rowsPerPageOptions={[5, 10, 20]}
            >
                <Column field="storyId" header="Story ID" body={storyIdTemplate} />
                <Column field="option" header="Story Option" />
                <Column field="timestamp" header="Timestamp" body={timestampTemplate} />
            </DataTable>
        </div>
    );


};

export default Stories;