import React, { useState, useEffect, useRef } from 'react';
import { getStory, generateOptions, saveOption } from './api';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';

// PrimeReact CSS imports
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '/node_modules/primeflex/primeflex.css';

import './App.css';

function App() {
  const [storyId, setStoryId] = useState(null);
  const [story, setStory] = useState('');
  const [options, setOptions] = useState([]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [storyLoaded, setStoryLoaded] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [loadStoryId, setLoadStoryId] = useState('');
  const storyEndRef = useRef(null);

  useEffect(() => {
    const storyIdFromUrl = searchParams.get('storyId');
    if (storyIdFromUrl) {
      loadStory(storyIdFromUrl);
    }
  }, [searchParams]);

  const loadStory = async (id = '') => {
    try {
      const response = await getStory(id);
      setOptions([]);
      setStory(response.data.story || 'Story not found');
      setStoryId(response.data.storyId);
      setStoryLoaded(true);
      navigate(`/app/?storyId=${response.data.storyId}`);
    } catch (error) {
      console.error('Error loading story:', error);
    }
  };

  const loadOptions = async () => {
    try {
      const response = await generateOptions(storyId);
      setOptions(Object.values(response.data || {}));
      setStoryLoaded(false);
    } catch (error) {
      console.error('Error generating options:', error);
    }
  };

  const handleSaveOption = async (optionId, optionText) => {
    try {
      await saveOption(optionId, storyId);
      setOptions([]);
      setIsTyping(true);

      const textToAdd = ` ${optionText}`;
      for (let i = 0; i < textToAdd.length; i++) {
        setTimeout(() => {
          setStory((prevStory) => prevStory + textToAdd[i]);
          if (i === textToAdd.length - 1) {
            setIsTyping(false);
          }
        }, i * 50);
      }

      loadOptions();
    } catch (error) {
      console.error('Error saving option:', error);
    }
  };

  useEffect(() => {
    if (storyEndRef.current) {
      storyEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [story]);

  const optionTemplate = (rowData) => {
    return (
      <Button
        label={`Choose ${rowData.option_id}`}
        onClick={() => handleSaveOption(rowData.option_id, rowData.option)}
        disabled={isTyping}
        className="p-button-sm"
      />
    );
  };

  return (
    <div className="App flex align-items-center flex-column">
      <div className="flex justify-content-between align-items-center mb-3 w-8">
        <h1>Interactive Story App</h1>
        <Link to="/">
          <Button label="Home" icon="pi pi-home" className="p-button-secondary" />
        </Link>
      </div>
      <Card title="Story Controls" className="mb-3 w-8">
        <Button label="Start a New Story" icon="pi pi-plus" onClick={() => loadStory()} className="w-full" disabled={isTyping} />
      </Card>
      <Card title="Story" className="mb-3 w-8">
        {storyId && <p className="text-sm">Story ID: {storyId}</p>}
        <p>{story}</p>
        <div ref={storyEndRef} />

      </Card>

      <Card title="Options" className="mb-3 w-8">
        <h3>Options:</h3>
        {storyLoaded && (
          <Button label="Generate Options" icon="pi pi-refresh" onClick={loadOptions} className="mb-3"></Button>
        )}
        <DataTable value={options} className="p-datatable-sm" rowHover>
          <Column body={(rowData, { rowIndex }) => (
            <Button
              label={`Choose ${rowIndex + 1}`}
              icon="pi pi-check"
              onClick={() => handleSaveOption(rowData.option_id, rowData.option)}
              disabled={isTyping}
              className="p-button-sm"
            />
          )} headerStyle={{ width: '150px' }} />
          <Column field="option" header="Option" />
        </DataTable>
      </Card>
    </div>
  );
}

export default App;